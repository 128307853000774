<script setup lang="ts">
	import { computed } from 'vue'
	import { useRouter } from 'vue-router'
	import { type LinkRouteMeta, type RouteRecordRaw, RouterLink } from 'vue-router'

	const router = useRouter()
	const links = computed<RouteRecordRaw[]>(() => {
		return router.options.routes
			?.filter(
				(route: RouteRecordRaw) =>
					(route.meta as LinkRouteMeta) && (route.meta as LinkRouteMeta).linkNavBar === true
			)
			.sort((a, b) => {
				return (a.meta as LinkRouteMeta).linkNavOrder - (b.meta as LinkRouteMeta).linkNavOrder
			})
	})
</script>

<template>
	<header class="sticky-top navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
		<nav class="container-xxl flex-wrap">
			<button
				class="navbar-toggler"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon"></span>
			</button>
			<RouterLink to="/" class="navbar-brand">Canderly Construction</RouterLink>

			<div id="navbarSupportedContent" class="collapse navbar-collapse">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li v-for="link in links" :key="link.name" class="nav-item">
						<RouterLink class="nav-link" active-class="active" :to="{ name: link.name }">
							{{ (link.meta as LinkRouteMeta).linkLabel }}
						</RouterLink>
					</li>
				</ul>
				<form class="d-flex" role="search">
					<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
					<button class="btn btn-outline-success" type="submit">Search</button>
				</form>
			</div>
		</nav>
	</header>
</template>
